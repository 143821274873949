import fetch from 'cross-fetch';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

export const client = new ApolloClient({
    link: new HttpLink({
        uri: process.env.GRAPHQL_API_URL,
        fetch,
    }),
    cache: new InMemoryCache()
});
// console.log(`AAAAABB: ${process.env.GRAPHQL_API_URL}`)
